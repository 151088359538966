import {ServicoModel} from "@/models/ServicoModel";
import ApiService from "./ApiService";
import {ConsultorModel} from "@/models/ConsultorModel";
import {MecanicoModel} from "@/models/MecanicoModel";

export const servicosList = async (codConcessionaria: number | string, postData: any): Promise<Array<ServicoModel>> => {
    const {data} = await ApiService.post(`/servicolist/${codConcessionaria}`, postData);
    return data;
};

export const servicoView = async (codServico: number) => {
    const {data} = await ApiService.get(`/servicoview/${codServico}`);
    return data;
};

export const servicoDelete = async (codServico: number): Promise<Array<ServicoModel>> => {
    const {data} = await ApiService.post(`/servicodelete/${codServico}`, {});
    return data;
};

export const servicoAdd = async (postData) => {
    const { data } = await ApiService.post('/servicoadd', postData);
    return data;
};

export const getDescricao = async (codConcessionaria: number) => {
    const { data } = await ApiService.get(`/servico/descricao/${codConcessionaria}`);
    return data;
};

export const servicoCopy = async (codConcessionaria: number): Promise<Array<ServicoModel>> => {
    const {data} = await ApiService.get(`/servico/copiar/${codConcessionaria}`);
    return data;
};

export const storeServicoCopy = async (postData: any): Promise<Array<ServicoModel>> => {
    const {data} = await ApiService.post('/servico/copiar', postData);
    return data;
};

export const consultoresList = async (codConcessionaria: number): Promise<Array<ConsultorModel>> => {
    const {data} = await ApiService.get(`/consultorlist/${codConcessionaria}`);
    return data;
};

export const consultorView = async (codConsultor: number) : Promise<ConsultorModel>  => {
    const {data} = await ApiService.get(`/consultorview/${codConsultor}`);
    return data;
};

export const consultorDelete = async (codConsultor: number): Promise<Array<ConsultorModel>> => {
    const {data} = await ApiService.post(`/consultordelete/${codConsultor}`, {});
    return data;
};

export const consultorAdd = async (postData) => {
    const { data } = await ApiService.post('/consultoradd', postData);
    return data;
};

export const mecanicoAdd = async (postData) => {
    const {data} = await ApiService.post('/mecanicoadd', postData);
    return data;
};

export const mecanicosList = async (codConcessionaria: number): Promise<Array<MecanicoModel>> => {
    const {data} = await ApiService.get(`/mecanicolist/${codConcessionaria}`);
    return data;
};

export const mecanicoView = async (codMecanico: number) : Promise<MecanicoModel> => {
    const {data} = await ApiService.get(`/mecanicoview/${codMecanico}`);
    return data;
};

export const mecanicoDelete = async (codMecanico: number): Promise<Array<MecanicoModel>> => {
    const {data} = await ApiService.post(`/mecanicodelete/${codMecanico}`, {});
    return data;
};

export const importExcel = async (codConcessionaria: number, codUsuario: number ,payload) => {
    const {data} = await ApiService.post(`/servico/importar/${codConcessionaria}/${codUsuario}`,payload)
    return data
}

export default {servicosList, servicoView, servicoDelete, servicoAdd, getDescricao, servicoCopy, storeServicoCopy, consultoresList, consultorView, consultorDelete, mecanicoAdd, mecanicosList, mecanicoView, mecanicoDelete, consultorAdd,importExcel};