
import {ref, watch} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import useAlert from "@/composables/Alert.ts";
import {hideModal} from '@/core/helpers/dom';
import {mecanicoDelete} from "@/services/CrudService";

export default {
  name: "deletarMecanicoModal",

  props: {
    mecanico: {
      type: Object,
      required: true,
    }
  },

  emits: ["atualizar-mecanicos"],

  setup(props, { emit }) {
    const logs: any = ref({});
    const { showTimeAlert } = useAlert();
    const animate = ref(false);

    watch(() => props.mecanico, () => {
      logs.value = props.mecanico
    });

    async function deletMecanico(codMecanico) {
      animate.value = true;
      await mecanicoDelete(codMecanico).then(() => {
        emit("atualizar-mecanicos");
        const elementModal = document.getElementById("deletarMecanicoModal");
        hideModal(elementModal);
        showTimeAlert("Mecânico excluído com sucesso");
      }).catch((e) => {
        showTimeAlert("Não foi possível completar a solicitação", "error");
      });
      animate.value = false;
    }

    function deletarMecanico() {
      if (props.mecanico) {
        Swal.fire({
          title: "Deseja excluir esse mecânico?",
          text: "Essa alteração não poderá ser desfeita!",
          showCancelButton: true,
          confirmButtonText: "Excluir mecânico",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-flex background_btn_1_opct text-white",
            cancelButton: "btn btn-light me-3",
          },
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            deletMecanico(props.mecanico?.codMecanico);
          }
        });
      }
    }

    return {
      logs,
      animate,
      deletarMecanico
    }
  }
}
